import axios from "@/axios"
const state = {
  forms: {},
  counters: {},
}

const getters = {
  allForms: state => state.forms.data,
  getCounters: state => state.counters,
  getFormCurrentPage: state => state.forms.current_page,
  getFormLastPage: state => state.forms.last_page,
}
const actions = {
  async fetchForms({ commit }, info) {
    var page = info.page
    var keyword = info.keyword
    if (keyword != "" && keyword != null) {
      const response = await axios.get(
        "/admin/forms?per_page=10&&page=" + page + "&&keyword=" + keyword
      )
      commit("SET_FORM_DATA", response.data.data)
    } else {
      const response = await axios.get("/admin/forms?per_page=10&&page=" + page)
      commit("SET_FORM_DATA", response.data.data)
    }
  },
  async fetchCounters({ commit }) {
    const response = await axios.get("/admin/count")
    commit("SET_COUNTERS", response.data.data)
  },
  async saveForm(_, form) {
    const response = await axios.post("/forms", form)
    if (response.status == 200)
      return { msg: "success", formId: response.data.data.unique_id }
    else return "failed"
  },
  async changeFormState(_, info) {
    const response = await axios.post("/admin/forms/" + info.id, {
      status: info.status,
      _method: "PATCH",
    })
    if (response.status == 200) return "success"
    else return "failed"
  },

  async getFormById(_, info) {
    const response = await axios.get(
      "/getFormsById?mobile=" + info.mobile + "&formId=" + info.formId
    )
    if (response.status == 200)
      return { msg: "success", data: response.data.data }
    else return "failed"
  },

  setFormCurrentPage({ commit }, currentPage) {
    commit("SET_FORM_CURRENT_PAGE", currentPage)
  },
}
const mutations = {
  SET_FORM_DATA: (state, data) => (state.forms = data),
  SET_COUNTERS: (state, data) => (state.counters = data),

  SET_FORM_CURRENT_PAGE: (state, data) => (state.forms.current_page = data),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
