<template>
  <v-footer absolute class="font-weight-medium text-subtitle-2">
    <v-col class="text-center py-1" cols="10">
      {{ new Date().getFullYear() }} — هيئة الاعلام والاتصالات / دائرة تنظيم
      تكنولوجيا المعلومات / قسم البرامج والتطبيقات الذكية
    </v-col>
    <v-col cols="2"></v-col>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style></style>
