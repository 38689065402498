<template>
  <div>
    <v-container class="userinfo">
      <h2 class="mb-5">معلومات الحساب</h2>
      <ChangePassword />
      <form>
        <v-row class="px-6">
          <v-col cols="12" md="6" lg="4">
            <v-col cols="12">
              <span>الاسم الكامل</span>
              <h3>{{ userInfo.name }}</h3>
            </v-col>

            <v-col cols="12">
              <span>اسم المستخدم</span>
              <h3>{{ userInfo.username }}</h3>
            </v-col>

            <v-col cols="12">
              <span>تاريخ الانشاء</span>
              <h3>{{ userInfo.created_at.substring(0, 10) }}</h3>
            </v-col>
          </v-col>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
import ChangePassword from "../../components/Models/ChangePassword.vue";

export default {
  name: "userInfo",
  components: { ChangePassword },
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {
    getback: function () {
      history.back();
    },
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
};
</script>

<style scoped lang="scss">
.texttitles {
  font-size: large;
}

h2 {
  text-align: center;
}
</style>
