<template>
  <div>
    <v-row
      dir="rtl"
      class="mb-3"
    >
      <v-col cols="12">
        <v-card>
          <v-col
            cols="11"
            md="5"
            sm="11"
          >
            <v-btn
              class="float-left mx-2"
              elevation="0"
              @click="searchInForms()"
            ><v-icon color="red">mdi-magnify </v-icon>
            </v-btn>
            <v-text-field
              dense
              justify-center
              v-model="search"
              label="بحث"
              outlined
              @keydown.enter="searchInForms()"
            ></v-text-field>
            <v-alert
              v-if="feedbackState"
              border="top"
              :type="feedbackType"
              dark
            >
              {{ feedbackText }}
            </v-alert>
          </v-col>
          <v-card-text>
            <v-data-table
              align-center
              :headers="headers"
              :items="allForms"
              hide-default-footer
              fixed-header
              dir="rtl"
            >
              <template v-slot:[`item.genderFormat`]="{ item }">
                <span v-if="item.gender == 'male'">ذكر</span>
                <span v-if="item.gender == 'female'">انثى</span>
              </template>
              <template v-slot:[`item.statusFormat`]="{ item }">
                <v-select
                  :value="item.status"
                  :items="statusItems"
                  item-text="text"
                  item-value="value"
                  dense
                  flat
                  style="width:120px;"
                  @change="changeFormStatus($event,item.id)"
                >

                </v-select>
              </template>

              <template v-slot:[`item.addressFormat`]="{ item }">
                <span v-if="item.address == 'insideiraq'">داخل العراق</span>
                <span v-if="item.address == 'outsideiraq'">خارج العراق </span>
              </template>

              <template v-slot:[`item.profileImageFormat`]="{ item }">
                <v-avatar size="36">
                  <img :src="item.profile_image">

                </v-avatar>

              </template>

              <template v-slot:[`item.socialMediaFormat`]="{ item }">
                <span
                  v-for="(si,index) in socialMediaItems"
                  :key="index"
                >
                  <a
                    v-if="item.social_media_links[si]"
                    :href="item.social_media_links[si]"
                    target="_blank"
                  ><v-chip color="primary">{{ si }}</v-chip></a>
                </span>
              </template>

              <template v-slot:[`item.videoLinkFormat`]="{ item }">
                <span v-if="item.video_link[0]">
                  <a
                    target="_blank"
                    :href="item.video_link[0]"
                  >
                    <v-chip>الرابط</v-chip>
                  </a>
                </span>
              </template>

            </v-data-table>
            <!-- pagination -->
            <v-pagination
              v-model="currentGet"
              :length="lastGet"
              total-visible="6"
              circle
              :disabled="disablePagination"
            ></v-pagination>
            <!-- pagination // -->
          </v-card-text>
          <!--   <complaint-dialog
            :ComplaintDialog="ComplaintDialog"
            :complaintData="complaintData"
            @complaintDialogClose="ComplaintDialog = $event"
          /> -->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
//import ComplaintDialog from "../../components/ComplaintDialog.vue";
export default {
  name: "FormsPage",
  components: {},
  computed: {
    ...mapGetters(["allForms"]),
    currentGet: {
      get() {
        return this.$store.getters.getFormCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setFormCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getFormLastPage;
      },
    },
  },
  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.info.page = newVal;
        this.info.keyword = this.search;
        this.disablePagination = true;
        this.fetchForms(this.info)
          .then(() => {
            this.disablePagination = false;
          })
          .catch(() => {
            this.disablePagination = false;
          });
      }
    },
  },
  created() {
    // this.fetchCounters();
    this.fetchForms(this.info);
  },

  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      // ComplaintDialog: false,
      feedbackText: "تنبيه",
      feedbackState: false,
      feedbackType: "info",
      socialMediaItems: ["facebook", "youtube", "twitter", "instagram"],
      complaintData: {},
      disablePagination: false,
      updateInfo: {
        id: null,
        status: null,
      },
      statusItems: [
        {
          text: "انتضار",
          value: "waiting",
        },
        {
          text: "جاري المعالجة",
          value: "under_processing",
        },
        {
          text: "مرفوض",
          value: "rejected",
        },
        {
          text: "مقبول",
          value: "accepted",
        },
      ],
      search: "",
      info: {
        page: 1,
        keyword: "",
      },
      headers: [
        { text: "الاسم", value: "fullname" },
        { text: "رقم الاستمارة", value: "unique_id" },
        { text: "حالة الاستمارة", value: "statusFormat" },
        { text: "تاريخ الميلاد", value: "birth_date" },
        { text: "الجنس", value: "genderFormat" },
        { text: "مكان التقديم", value: "addressFormat" },
        { text: "وصف عن صاحب المحتوى", value: "user_bio" },
        { text: "الصورة الشخصية", value: "profileImageFormat" },
        { text: "الموبايل", value: "mobile" },
        { text: "email", value: "email" },
        { text: "مواقع التواصل", value: "socialMediaFormat" },
        { text: "فديو تعريفي ", value: "videoLinkFormat" },
        { text: "تاريخ التقديم", value: "created_at" },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchForms", "changeFormState"]),
    changeFormStatus(event, id) {
      if (!this.statusItems.filter((e) => e.value === event).length > 0) {
        return;
      }
      if (!id) return;
      this.updateInfo.id = id;
      this.updateInfo.status = event;

      this.changeFormState(this.updateInfo)
        .then((res) => {
          if (res == "success") {
            this.feedbackState = true;
            this.feedbackText = "العملية تمت بنجاح";
            this.feedbackType = "success";
          } else {
            this.feedbackState = true;
            this.feedbackText = "حدث خطاء حاول لاحقا";
            this.feedbackType = "warning";
          }
          setTimeout(() => {
            this.feedbackState = false;
          }, 2000);
        })
        .catch(() => {
          this.feedbackState = true;
          this.feedbackText = "حدث خطاء حاول لاحقا";
          this.feedbackType = "error";
          setTimeout(() => {
            this.feedbackState = false;
          }, 2000);
        });
    },
    searchInForms() {
      this.info.page = 1;
      this.info.keyword = this.search;
      this.fetchForms(this.info);
    },
  },
};
</script>

<style lang="scss" scoped></style>
