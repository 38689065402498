import axios from "@/axios"
const state = {
  token: "",
  userInfo: {},
}
const getters = {
  authenticated: state => state.token && state.userInfo,
  getUser: state => state.userInfo,
  isLoggedIn: state => (state.token ? true : false),
}
const actions = {
  async loginFun({ dispatch }, cred) {
    var obj = JSON.parse(cred)
    const response = await axios.post("/auth/login", {
      username: obj.username,
      password: obj.password,
    })
    if (response.data.access_token) {
      return dispatch("attempt", response.data.access_token)
    } else {
      return response.data
    }
  },
  async changePassword(_, info) {
    const response = await axios.post("/auth/reset", info)

    if (response.data.message == "updated") {
      return "updated"
    } else return "failed"
  },

  async attempt({ commit, state }, token) {
    if (token) commit("setToken", token)
    if (!state.token) return
    try {
      const response = await axios.post("/auth/me")
      localStorage.setItem("userInfo", JSON.stringify(response.data.data))
      commit("setUser", response.data.data)

      return "success"
    } catch {
      console.log("err")
      commit("setToken", null)
      commit("setUser", null)
    }
  },
  signout({ commit }) {
    return axios.post("/auth/logout").then(() => {
      commit("setToken", null)
      commit("setUser", null)
    })
  },
}
const mutations = {
  setToken: (state, user_token) => (state.token = user_token),
  setUser: (state, userInfo) => (state.userInfo = userInfo),
}
export default {
  state,
  getters,
  actions,
  mutations,
}
