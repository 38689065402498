<template>
  <div class="login">
    <v-container>
      <div>
        <v-row justify="center">
          <v-col
            cols="12"
            md="5"
            lg="5"
            xl="4"
            class="contentframe pb-8"
          >
            <v-row class="px-8"><v-col
                cols="4"
                class="text-center"
              >
                <img
                  style="vertical-align: middle"
                  width="90%"
                  height="auto"
                  src="../assets/imgs/cmclogo.png"
                  alt
                /></v-col><v-col
                cols="8"
                class="pt-6"
              >
                <h4 class="text-right blue-text">جمهورية العراق</h4>
                <h3 class="text-right blue-text">هيئة الاعلام والاتصالات</h3>
              </v-col></v-row>

            <div class="px-5 mx-5 pt-5 text-right">
              <h4 class="mb-5 text-right">تسجيل الدخول</h4>
              <v-alert
                class="pt-3"
                v-if="feedback"
                dense
                type="warning"
              >
                {{ feedback }}
              </v-alert>
              <v-form
                ref="loginForm"
                lazy-validation
              >
                إسم المستخدم
                <v-text-field
                  auto
                  label="إسم المستخدم"
                  required
                  placeholder="إسم المستخدم"
                  class="mt-2"
                  v-model="cred.username"
                  dense
                  outlined
                  :rules="requiredRule"
                ></v-text-field>كلمة المرور
                <v-text-field
                  label="كلمة المرور"
                  required
                  placeholder="كلمة المرور"
                  type="password"
                  class="mt-2"
                  dense
                  outlined
                  v-model="cred.password"
                  :rules="requiredRule"
                  @keyup.enter="login"
                ></v-text-field>

                <v-btn
                  class="login-system-btn text-capitalize"
                  outlined
                  text
                  @click="login()"
                ><v-icon left>mdi-login</v-icon> تسجيل الدخول</v-btn>
              </v-form>

              <v-card
                class="mt-4 pa-4 text-right sys-card"
                elevation="0"
              >
                <v-row dir="ltr">
                  <v-col
                    cols="12"
                    class="text-left pe-1 pt-2 ps-2 pe-3"
                  >
                    <span class="d-block eng-text blue-text comment-text">
                      Department of programming | IT Regulation directorate
                    </span>
                    <span class="d-block eng-text blue-text comment-text">
                      &copy; The Communication and Media Commission of Iraq
                      (CMC)
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LoginPage",
  data: function () {
    return {
      cred: {
        username: "",
        password: "",
      },
      feedback: "",
      loading: false,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
    };
  },
  methods: {
    ...mapActions(["loginFun"]),
    login() {
      if (!this.$refs.loginForm.validate()) return;
      this.loginFun(JSON.stringify(this.cred))
        .then((response) => {
          if (response == "success") {
            this.cred.username = "";
            this.cred.password = "";
            this.feedback = "";
            this.$router.push("/dashboard/main");
          } else this.feedback = "إسم المستخدم أو كلمة المرور غير صالحة";
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status == 422) {
            if (error.response.data) {
              if (
                error.response.data.password[0] ==
                "The password must be at least 6 characters."
              )
                this.feedback = "يجب ان تتكون كلمة السر من 6 احرف على الاقل";
            }
          } else if (error.response.status == 401) {
            this.feedback = "إسم المستخدم أو كلمة المرور غير صالحة";
          }
          /*   switch (error.response.data) {
            case 400:
              this.feedback = "تم حضر حسابك. الرجاء الاتصال بمدير النظام";
              break;

            default:
              this.feedback = "إسم المستخدم أو كلمة المرور غير صالحة";
              break;
          } */
        });
    },
  },
};
</script>

<style scoped lang="scss">
$accentColor: #2196f3;

$fontname: "JF Flat";

.sys-card {
  background-color: #f1f1f1 !important;
}

.login {
  min-height: 100vh;
  text-align: center;
  direction: rtl;
  font-family: $fontname;
  // background: url($bgimage_path);
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
}

.contentframe {
  background: #fff;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
  margin-top: 5vh;
  padding-top: 4vh;
}

.centered-input {
  text-align: center !important;
}

h2 {
  text-align: center;
}

.comment-text {
  font-size: 11px !important;
}
#blureField {
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
}

.login-system-btn {
  background-color: #0f335f !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #f1f1f1 !important;
}

.login-system-btn-outline {
  background-color: transparent !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #0f335f !important;
  border: 1px solid #0f335f !important;
}

.right-bordered {
  border-right: 1px solid #0f335f !important;
}

@media only screen and (max-width: 600px) {
  .contentframe {
    border: none;
    box-shadow: none;
    border-radius: 10px;
    margin-top: 0vh;
  }
  .login {
    background: #ffffff;
  }
}
</style>
