<template>
  <div
    class="text-center"
    v-if="FormInfoDialog"
  >
    <v-dialog
      :value="FormInfoDialog"
      width="600"
    >
      <v-card v-click-outside="handleClickOutside">
        <v-card-text dir="rtl">
          <h2 class="text-center pa-2 mb-3">متابعة تفاصيل الاستمارة</h2>
          <v-divider class="mb-2"></v-divider>
          <v-row>
            <v-col
              cols="12"
              md="10"
              class="mx-auto"
            >

              <v-alert
                v-if="feedbackState"
                border="top"
                :type="feedbackType"
                dark
              >
                {{ feedbackText }}
              </v-alert>
            </v-col>
          </v-row>

          <div v-if="step==1">
            <v-form ref="check_form">
              <v-row class="mt-3">
                <v-col
                  cols="12"
                  md="10"
                  class="mx-auto"
                >
                  <v-text-field
                    v-model="Info.mobile"
                    type="number"
                    required
                    placeholder="مثال:07712345678"
                    hint="مثال:07712345678"
                    label="رقم الهاتف"
                    outlined
                    :rules="mobileRules"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                  class="mx-auto"
                >
                  <v-text-field
                    v-model="Info.formId"
                    type="number"
                    required
                    placeholder="رقم الاستمارة"
                    label="رقم الاستمارة"
                    outlined
                    :rules="requiredRule"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                  class="mx-auto text-center"
                >
                  <v-btn
                    :loading="loadingSpinner"
                    :disabled="loadingSpinner"
                    large
                    class="pa-4"
                    color="primary"
                    @click="findFormFn"
                  ><v-icon
                      small
                      class="pe-1 ps-1 small-text"
                    >mdi-magnify</v-icon>
                    بحث عن المعلومات
                  </v-btn>
                </v-col>
              </v-row>

            </v-form>
          </div>
          <div v-if="step==2">
            <div
              v-if="formDetails.unique_id"
              class="mb-2"
            >
              <strong>رقم الاستمارة</strong>: <span>{{ formDetails.unique_id }}</span>
            </div>
            <div
              v-if="formDetails.fullname"
              class="mb-2"
            >
              <strong>الاسم</strong>: <span>{{ formDetails.fullname }}</span>
            </div>

            <div
              v-if="formDetails.birth_date"
              class="mb-2"
            >
              <strong>تاريخ الميلاد</strong>: <span>{{ formDetails.birth_date }}</span>
            </div>

            <div
              v-if="formDetails.gender"
              class="mb-2"
            >
              <strong>الجنس </strong>: <span>{{ formDetails.gender=='male'?'ذكر':'انثى' }}</span>
            </div>

            <div
              v-if="formDetails.status"
              class="mb-2"
            >
              <strong>حالة الاستمارة </strong>: <span>{{ formDetails.status=='waiting'?'انتظار':formDetails.status=='under_processing'?'قيد المعالجة':formDetails.status=='accepted'?'تمت الموافقة':'مرفوض' }}</span>
            </div>

          </div>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="closeDialog"
          > اغلاق </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CheckFormInfoDialog",
  props: ["FormInfoDialog"],
  data: function () {
    return {
      formDetails: {},
      feedbackText: "تنبيه",
      feedbackState: false,
      feedbackType: "info",
      loadingSpinner: false,
      step: 1,
      Info: {
        mobile: null,
        formId: null,
      },
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      mobileRules: [
        (v) => !!v || "هذا الحقل مطلوب",
        (v) => String(v).length == 11 || "رقم الهاتف يجب ان يكون 11 رقم",
      ],
    };
  },

  methods: {
    ...mapActions(["getFormById"]),
    closeDialog() {
      this.$emit("formInfoDialogClose", false);
    },
    findFormFn() {
      if (!this.$refs.check_form.validate()) {
        return;
      }
      this.loadingSpinner = true;
      this.getFormById(this.Info)
        .then((res) => {
          if (res.msg == "success") {
            this.feedbackState = false;
            this.formDetails = res.data;
            this.step = 2;
          } else {
            this.feedbackState = true;
            this.feedbackText = "حدث خطاء حاول لاحقا";
            this.feedbackType = "warning";
          }
          this.loadingSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data) {
            if (err.response.data.message == "not found record") {
              this.feedbackState = true;
              this.feedbackText = "الرقم الذي ادخلته غير موجود!";
              this.feedbackType = "warning";
            }
          } else {
            this.feedbackState = true;
            this.feedbackText = "حدث خطاء حاول لاحقا";
            this.feedbackType = "error";
          }
          if (err.response.status == 500) {
            this.feedbackState = true;
            this.feedbackText = "مشكلة في الخادم حاول لاحقا";
            this.feedbackType = "error";
          }

          this.loadingSpinner = false;
          this.step = 1;
        });
    },
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
  },
};
</script>
