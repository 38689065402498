<template>
  <div
    class="text-center"
    v-if="AlertDialog"
  >
    <v-dialog
      :value="AlertDialog"
      width="500"
    >
      <v-card v-click-outside="handleClickOutside">
        <v-card-text v-if="AlertDialogStatus == 'success'">
          <div class="text-center pa-4">
            <v-icon
              x-large
              color="green"
            > mdi-check </v-icon>
          </div>

          <h2 class="text-center mb-3"> العملية تمت بنجاح</h2>
          <div class="text-center title mb-4">رقم الاستمارة: {{ formId }}</div>
          <div class="text-center red--text">*يرجى الاحتفاظ بالرقم من اجل متابعة حالة الطلب</div>
        </v-card-text>
        <v-card-text v-else-if="AlertDialogStatus == 'error'">
          <div class="text-center pa-4">
            <v-icon
              x-large
              color="red"
            > mdi-message-alert </v-icon>
          </div>

          <h2 class="text-center">حدث خطاء الرجاء المحاولة لاحقا</h2>
        </v-card-text>
        <v-card-text v-else-if="AlertDialogStatus == 'warning'">
          <div class="text-center pa-4">
            <v-icon
              x-large
              color="amber"
            > mdi-message-alert </v-icon>
          </div>
          <h2 class="text-center">الرجاء ملئ جميع الحقول</h2>
        </v-card-text>
        <v-card-text v-else-if="AlertDialogStatus == 'imageSize'">
          <div class="text-center pa-4">
            <v-icon
              x-large
              color="red"
            > mdi-image-size-select-small </v-icon>
          </div>
          <h2 class="text-center">الرجاء اختيار صورة يكون حجمها اقل من 3MB</h2>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="closeDialog"
          > اغلاق </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "AlertDialog",
  props: ["AlertDialog", "AlertDialogStatus", "formId"],
  data: function () {
    return {};
  },
  methods: {
    closeDialog() {
      this.$emit("closeAlertDialog", false);
    },
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
  },
};
</script>
