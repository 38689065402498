<template>
  <div class="form-cover px-3 px-md-0">
    <v-card
      elevation="3"
      max-width="600"
      class="mx-auto "
    >
      <v-container class=" mt-3 justify-center">

        <div class="text-center pt-3">
          <v-img
            responsive
            src="@/assets/imgs/cmclogo.png"
            width="120px"
            class="mx-auto"
          ></v-img>
        </div>

        <h2 class="text-center pt-4 mb-1 title-text">هيئة الاعلام والاتصالات</h2>
        <h3 class="text-center mb-3 title-text">
          استمارة التسجيل لاصحاب المحتوى الهادف
        </h3>
        <v-form
          ref="creativity_form"
          lazy-validation
          class="pa-0 pa-md-3"
        >
          <v-row class="mx-auto">
            <v-col
              cols="12"
              md="11"
              class="mx-auto "
            >
              <v-file-input
                class="d-flex-1"
                @change="Preview_image"
                v-model="Form.profileImage"
                placeholder="الصورة الشخصية"
                append-icon="mdi-camera"
                clearable
                :rules="requiredRule"
              >
                <template v-slot:append>
                  <v-avatar
                    v-if="Form.url==null"
                    size="40"
                    class="ma-1"
                  >
                    <v-img src="@/assets/imgs/avatar.png"></v-img>
                  </v-avatar>

                  <v-avatar
                    class="ma-1"
                    v-if="Form.url!=null"
                    size="40"
                  >
                    <v-img :src="Form.url"></v-img>
                  </v-avatar>
                </template>
              </v-file-input>
            </v-col>

            <v-col
              cols="12"
              md="11"
              class="mx-auto"
            >
              <p class="header-text text-right mb-3 mb-md-4"> الاسم الكامل</p>

              <v-text-field
                v-model="Form.fullname"
                type="text"
                required
                placeholder="الاسم الكامل"
                label="الاسم الكامل"
                class="text-right"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="11"
              class="mx-auto pt-0"
            >
              <p class="header-text text-right mb-3 mb-md-4"> تاريخ الميلاد</p>

              <v-menu
                v-model="datePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="Form.birthdate"
                    label="تاريخ الميلاد"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    outlined
                    :rules="requiredRule"
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="Form.birthdate"
                  @input="datePickerMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              md="11"
              class="mx-auto"
            >
              <p class="header-text text-right mb-3 mb-md-4">اختر الجنس</p>

              <v-radio-group
                v-model="Form.gender"
                row
                :rules="requiredRule"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    v-for="(item, i) in genders"
                    :key="i"
                  >
                    <v-radio :value="item.value">
                      <template v-slot:label>
                        <div>{{ item.text }}</div>
                      </template>
                    </v-radio>
                  </v-col></v-row>
              </v-radio-group>
            </v-col>

            <v-col
              cols="12"
              md="11"
              class="mx-auto"
            >
              <p class="header-text text-right mb-3 mb-md-4"> العنوان</p>

              <v-select
                v-model="Form.address"
                :items="locations"
                required
                placeholder="العنوان"
                label="العنوان"
                outlined
                :rules="requiredRule"
                class="pa2"
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="11"
              class="mx-auto"
            >
              <p class="header-text text-right mb-3 mb-md-4"> وصف عن صاحب المحتوى</p>

              <v-textarea
                v-model="Form.bio"
                rows="2"
                required
                placeholder="السيرة الذاتية"
                label="السيرة الذاتية"
                outlined
                :rules="requiredRule"
                class="text-right"
              ></v-textarea>
            </v-col>

            <v-col
              cols="12"
              md="11"
              class="mx-auto"
            >
              <p class="header-text text-right mb-3 mb-md-4">رقم الهاتف</p>

              <v-text-field
                v-model="Form.mobile"
                type="number"
                required
                placeholder="مثال:07712345678"
                hint="مثال:07712345678"
                label="رقم الهاتف"
                outlined
                :rules="mobileRules"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="11"
              class="mx-auto"
            >
              <p class="header-text text-right mb-3 mb-md-4"> البريد الالكتروني</p>

              <v-text-field
                v-model="Form.email"
                type="email"
                required
                placeholder="Email"
                label="Email"
                outlined
                :rules="emailRules"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="11"
              class="mx-auto"
            >
              <p class="header-text text-right mb-3 mb-md-4"> رابط فديو ابداعي لصاحب المحتوى</p>

              <v-text-field
                v-model="Form.videoLink"
                type="text"
                required
                placeholder="رابط فديو ابداعي"
                label="رابط فديو ابداعي"
                outlined
                :rules="requiredRule"
              ></v-text-field>
              <v-divider></v-divider>
            </v-col>

            <v-col
              cols="12"
              md="11"
              class="mx-auto"
            >
              <h3>روابط التواصل الاجتماعي</h3>
              <v-checkbox
                v-model="Form.facebookCheck"
                label="Facebook"
                class="d-inline-block ml-2 ml-md-3"
              ></v-checkbox>

              <v-checkbox
                v-model="Form.youtubeCheck"
                label="Youtube"
                class="d-inline-block ml-2 ml-md-3"
              ></v-checkbox>

              <v-checkbox
                v-model="Form.twitterCheck"
                label="Twitter"
                class="d-inline-block ml-2 ml-md-3"
              ></v-checkbox>

              <v-checkbox
                v-model="Form.instagramCheck"
                label="Instagram"
                class="d-inline-block ml-2 ml-md-3"
              ></v-checkbox>

            </v-col>

            <v-col
              v-if="Form.facebookCheck==true"
              cols="12"
              md="11"
              class="mx-auto"
            >

              <v-text-field
                v-model="Form.facebookLink"
                required
                placeholder="رابط الفيسبوك"
                label="رابط الفيسبوك"
                solo
                :rules="requiredRule"
                class="text-right"
              >
                <template v-slot:append>
                  <v-icon
                    slot="append-icon"
                    color="primary"
                  >mdi-facebook</v-icon>
                </template>

              </v-text-field>
            </v-col>

            <v-col
              v-if="Form.youtubeCheck"
              cols="12"
              md="11"
              class="mx-auto"
            >

              <v-text-field
                v-model="Form.youtubeLink"
                required
                placeholder="رابط قناة اليوتيوب"
                label="رابط قناة اليوتيوب"
                solo
                :rules="requiredRule"
                class="text-right"
              >
                <template v-slot:append>
                  <v-icon
                    slot="append-icon"
                    color="red"
                  >mdi-youtube</v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col
              v-if="Form.twitterCheck"
              cols="12"
              md="11"
              class="mx-auto"
            >

              <v-text-field
                v-model="Form.twitterLink"
                required
                placeholder="رابط صفحة التويتر"
                label="رابط صفحة التويتر"
                solo
                :rules="requiredRule"
                class="text-right"
              >
                <template v-slot:append>
                  <v-icon
                    slot="append-icon"
                    color="blue"
                  >mdi-twitter</v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col
              v-if="Form.instagramCheck"
              cols="12"
              md="11"
              class="mx-auto"
            >
              <v-text-field
                v-model="Form.instagramLink"
                required
                placeholder="رابط صفحة الانستاكرام"
                label="رابط صفحة الانستاكرام"
                solo
                :rules="requiredRule"
                class="text-right"
              >
                <template v-slot:append>
                  <v-icon
                    slot="append-icon"
                    color="deep-orange"
                  >mdi-instagram</v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="11"
              class="mx-auto text-center"
            >

              <v-btn
                :loading="loadingSpinner"
                :disabled="loadingSpinner"
                large
                class="pa-4"
                color="red"
                outlined
                @click="submitForm"
              ><v-icon
                  small
                  class="pe-1 ps-1 small-text"
                >mdi-file-plus-outline</v-icon>
                تسجيل المعلومات
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              md="11"
              class="mx-auto text-left pb-6"
            >
              <span class="footer-text">
                Copywrites &copy; the communication and media commission of Iraq
                (CMC)
              </span>
              <span class="footer-text">IT Regulation Directorate | Department of Programming</span>
            </v-col>
          </v-row>
        </v-form>
        <alert-dialog
          :AlertDialog="AlertDialog"
          @closeAlertDialog="AlertDialog = $event"
          :AlertDialogStatus="AlertDialogStatus"
          :formId="formId"
        ></alert-dialog>
      </v-container>
    </v-card>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import Vue from "vue";
import AlertDialog from "../components/AlertDialog.vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LciiMYkAAAAALwAsMMF6Yq9YIiXCXsQEJsloeLT" });

export default {
  name: "FormRegPage",
  components: { AlertDialog },
  data: function () {
    return {
      datePickerMenu: false,
      loadingSpinner: false,
      AlertDialog: false,
      AlertDialogStatus: null,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      emailRules: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "الرجاء كتابة الايميل بصورة صحيحة",
      ],
      mobileRules: [
        (v) => !!v || "هذا الحقل مطلوب",
        (v) => String(v).length == 11 || "رقم الهاتف يجب ان يكون 11 رقم",
      ],

      cities: [
        { text: "بغداد", value: "Baghdad" },
        { text: "البصرة", value: "Basra" },
        { text: "نينوى", value: "Ninawa" },
        { text: "الانبار", value: "Al-Anbar" },
        { text: "ديالى", value: "Diyala" },
        { text: "صلاح الدين", value: "Salah_Al-Din" },
        { text: "النجف الاشرف", value: "Najaf" },
        { text: "كربلاء المقدسة", value: "Karbala" },
        { text: "القادسية", value: "Al-Qādisiyyah" },
        { text: "المثنى", value: "Muthanna" },
        { text: "ذي قار", value: "DhiQar" },
        { text: "ميسان", value: "Maysan" },
        { text: "بابل", value: "Babil" },
        { text: "واسط", value: "Wasit" },
        { text: "اربيل", value: "Erbil" },
        { text: "السليمانية", value: "Sulaymaniyah" },
        { text: "دهوك", value: "Duhok" },
        { text: "كركوك", value: "Kirkuk" },
      ],

      genders: [
        { text: "ذكر", value: "male" },
        { text: "انثى", value: "female" },
      ],
      locations: [
        { text: "داخل العراق", value: "insideiraq" },
        { text: "خارج العراق", value: "outsideiraq" },
      ],
      formData: new FormData(),
      formId: null,
      Form: {
        fullname: null,
        birthdate: null,
        gender: null,
        location: null,
        bio: null,
        profile_image: null,
        mobile: null,
        email: null,
        socialMediaLinks: {},
        videoLinks: [],
        profileImage: null,
        url: null,
        videoLink: "",
        facebookLink: "",
        youtubeLink: "",
        twitterLink: "",
        instagramLink: "",
        /*  */
        facebookCheck: false,
        youtubeCheck: false,
        twitterCheck: false,
        instagramCheck: false,
      },
    };
  },
  created() {
    /*  this.AlertDialog = true;
    this.formId = "12312313";
    this.AlertDialogStatus = "success"; */
  },
  methods: {
    ...mapActions(["saveForm"]),
    Preview_image() {
      if (this.Form.profileImage)
        this.Form.url = URL.createObjectURL(this.Form.profileImage);
      else this.Form.url = null;
    },

    submitForm() {
      if (!this.$refs.creativity_form.validate()) {
        this.AlertDialogStatus = "warning";
        this.AlertDialog = true;
        return;
      }
      this.formData = new FormData();

      this.Form.videoLinks = [];
      this.Form.socialMediaLinks = {};
      if (this.Form.facebookCheck)
        this.Form.socialMediaLinks.facebook = this.Form.facebookLink;
      if (this.Form.youtubeCheck)
        this.Form.socialMediaLinks.youtube = this.Form.youtubeLink;
      if (this.Form.twitterCheck)
        this.Form.socialMediaLinks.twitter = this.Form.twitterLink;
      if (this.Form.instagramCheck)
        this.Form.socialMediaLinks.instagram = this.Form.instagramLink;

      if (this.Form.videoLink != "")
        this.Form.videoLinks.push(this.Form.videoLink);
      console.log(this.Form.videoLinks);

      this.formData.append("fullname", this.Form.fullname);
      this.formData.append("birth_date", this.Form.birthdate);
      this.formData.append("gender", this.Form.gender);
      this.formData.append("address", this.Form.address);
      this.formData.append("user_bio", this.Form.bio);
      this.formData.append("mobile", this.Form.mobile);
      this.formData.append("email", this.Form.email);
      this.formData.append(
        "social_media_links",
        JSON.stringify(this.Form.socialMediaLinks)
      );
      this.formData.append("video_link[0]", this.Form.videoLinks);
      this.formData.append("profile_image", this.Form.profileImage);
      console.log(this.Form.socialMediaLinks);
      this.loadingSpinner = true;
      this.$recaptcha("login").then((token) => {
        this.formData.append("g-recaptcha-response", token);
        this.saveForm(this.formData)
          .then((res) => {
            if (res.msg == "success") {
              this.loadingSpinner = false;
              this.formId = res.formId;
              this.AlertDialogStatus = "success";
              this.AlertDialog = true;
              // this.$refs.creativity_form.reset();
              this.Form.socialMediaLinks = {};
              this.Form.videoLinks = [];
            } else {
              this.loadingSpinner = false;
              this.AlertDialogStatus = "error";
              this.AlertDialog = true;
            }
          })
          .catch((err) => {
            this.loadingSpinner = false;
            console.log(err);

            if (err.response.data.message) {
              if (
                err.response.data.message ==
                "The profile image must not be greater than 3048 kilobytes."
              ) {
                this.AlertDialogStatus = "imageSize";
                this.AlertDialog = true;
              }
            } else {
              this.AlertDialogStatus = "error";
              this.AlertDialog = true;
            }
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form-cover {
  background-image: url("https://images.unsplash.com/photo-1529071242804-840f9a164b8b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 3rem;
  padding-bottom: 5rem;
}

.header-text {
  background-color: #8d99ae !important;
  color: #edf2f4;
  padding: 2%;
  border-radius: 5px;
}

.footer-text {
  font-size: smaller;
  display: block;
  font-family: sans-serif;
  color: silver;
}

.title-text {
  font-weight: normal;
}
</style>
