import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./scss/main.scss";
import vuetify from "./plugins/vuetify";

require("@/store/modules/subscriber");
Vue.config.productionTip = false;
store.dispatch("attempt", localStorage.getItem("user_token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
