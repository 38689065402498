<template>
  <div class="user-dashboard">
    <AppBar />
    <v-container fluid>
      <v-row class="px-2 px-md-6">
        <v-col cols="2" sm="2" md="2" class="HiddenDiv">
          <AppSideBar />
        </v-col>
        <v-col cols="10" sm="10" md="10" class="scroll-y mt-3">
          <router-view />
        </v-col>
      </v-row>
      <AppFooter />
    </v-container>
  </div>
</template>

<script>
import AppBar from "../../components/AppBar";
import AppSideBar from "../../components/AppSideBar";
import AppFooter from "../../components/AppFooter";

export default {
  name: "UserDashboard",
  components: {
    AppBar,
    AppSideBar,
    AppFooter,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scopped>
body,
html {
  overflow: hidden;
  height: 100vh;
}
.user-dashboard {
  display: block;
  width: 100%;
  height: 100%;
  float: left;
  background-color: #f8f8f8;
  .scroll-y {
    overflow: auto;
    max-height: 80vh;
    padding-bottom: 3rem;
  }
}
</style>
