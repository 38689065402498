<template>
  <div>
    <v-row
      dir="rtl"
      class="mb-3"
    >
      <v-col
        cols="12"
        md="4"
        v-for="card in statisticsCards"
        :key="card.title"
      >
        <v-card
          elevation="1"
          class="text-center"
        >
          <v-card-title class="d-block mb-3">
            <v-icon
              large
              color="indigo lighten-2"
            >{{ card.icon }}</v-icon>
          </v-card-title>
          <v-card-subtitle>{{ card.title }}</v-card-subtitle>
          <v-card-text class="black--text text-h5 font-weight-light">
            {{ getCounters[card.countName] }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MainPage",
  data() {
    return {
      statisticsCards: [
        {
          icon: "mdi-text-box-check",
          title: "عدد المتقدمين الكلي",
          countName: "totalForms",
        },
        {
          icon: "mdi-human-male",
          title: "عدد الذكور",
          countName: "maleCount",
        },
        {
          icon: "mdi-human-female",
          title: "عدد الاناث",
          countName: "femaleCount",
        },
        {
          icon: "mdi-gesture-tap-hold",
          title: "انتظار",
          countName: "statusWaiting",
        },
        {
          icon: "mdi-cached",
          title: "جاري المعالجة",
          countName: "statusUnderProcessing",
        },
        {
          icon: "mdi-cancel",
          title: "مرفوض",
          countName: "statusRejected",
        },
        {
          icon: "mdi-thumb-up",
          title: "مقبول",
          countName: "statusAccepted",
        },

        {
          icon: "mdi-arrow-down",
          title: "داخل العراق",
          countName: "insideIraqCount",
        },
        {
          icon: "mdi-arrow-up",
          title: "خارج العراق",
          countName: "outsideIraqCount",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCounters"]),
  },
  created() {
    this.fetchCounters();
  },

  methods: {
    ...mapActions(["fetchCounters"]),
  },
};
</script>

<style lang="scss" scoped></style>
