import { render, staticRenderFns } from "./AppSideBar.vue?vue&type=template&id=d663c7c8&"
import script from "./AppSideBar.vue?vue&type=script&lang=js&"
export * from "./AppSideBar.vue?vue&type=script&lang=js&"
import style0 from "./AppSideBar.vue?vue&type=style&index=0&id=d663c7c8&prod&lang=scss&scopped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports