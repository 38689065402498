<template>
  <div class="wrapper">
    <div class="container py-4 text-center text-light">
      <div class="header">
        <div class="text-center py-1">
          <div class="d-block d-sm-none">
            <img
              src="../assets/imgs/cmclogo.png"
              width="120"
              height="100"
              alt
              class="mt-2"
            />
          </div>
          <div class="d-none d-sm-block">
            <img
              src="../assets/imgs/cmclogo.png"
              width="150"
              height="120"
              alt
              class="mt-2"
            />
          </div>
        </div>
        <h1 class="">هيئة الاعلام والاتصالات</h1>
        <h3 class="eng">Communications and Media Commisson</h3>
      </div>

      <div class="mt-4 header-text mb-5 grey lighten-2">
        <p class="black--text pt-2">
          استمارة التسجيل لاصحاب المحتوى الهادف
        </p>
      </div>

      <v-btn
        large
        class="send-btn mb-3 ps-2 pe-4 small-text"
        to="form"
      > ملء استمــارة جديدة</v-btn>
      <br />
      <v-btn
        large
        class="ps-2 pe-4 small-text mb-2"
        outlined
        @click="showGetFormInfoModel"
      >متابعة حالةالاستمارة</v-btn>
      <br />

      <v-col
        cols="11"
        class="mx-auto text-center mt-5 pb-6"
      >
        <span class="footer-text">
          Copywrites &copy; the communication and media commission of Iraq (CMC)
        </span>
        <span class="footer-text">IT Regulation Directorate | Department of Programming</span>
      </v-col>
    </div>

    <CheckFormInfoDialog
      :FormInfoDialog="FormInfoDialog"
      @formInfoDialogClose=" FormInfoDialog = $event"
    />
  </div>
</template>

<script>
import CheckFormInfoDialog from "../components/CheckFormInfoDialog.vue";
export default {
  components: { CheckFormInfoDialog },
  name: "IndexPage",
  data: function () {
    return {
      FormInfoDialog: false,
    };
  },
  methods: {
    showGetFormInfoModel() {
      this.FormInfoDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.send-btn {
  background-color: #d90429 !important;
  color: #edf2f4 !important;
  border: 2px solid #ef233c;
}
.colored-text {
  color: #d90429 !important;
}

.header-text {
  padding: 2%;
  border-radius: 5px;
}
.footer-text {
  font-size: smaller;
  display: block;
  font-family: sans-serif;
  color: silver;
}
.wrapper {
  font-family: flat;
  display: block;
  width: 100%;
  height: 100vh;
  //background-image: url("../assets/imgs/appleComp5.jpg");
  background-color: #f1f1f1;
  background-position: center center;
  background-size: cover;

  .content {
    font-size: 20px;
    background: rgba(43, 43, 43, 0.3);
    border-radius: 10px;
    padding: 2rem;
    width: 90%;
    margin-top: 50px;
    box-shadow: 3px 1px 10px 0px rgba(0, 0, 0, 0.5);
    margin-left: auto;
    margin-right: auto;
  }
  .lead {
    font-size: 25px;
    color: #fff;
  }
  .eng {
    font-family: sans-serif;
  }
  .dimme {
    color: #fff !important;
    font-weight: 100 !important;
  }
}
</style>
