import Vue from "vue";
import VueRouter from "vue-router";

import UserDashboard from "../views/dashboard/UserDashboard.vue";
import IndexPage from "../views/IndexPage.vue";
import FormPage from "../views/FormPage.vue";
import LoginPage from "../views/Login.vue";
import MainPage from "../views/dashboard/MainPage.vue";
import UserInfo from "../views/dashboard/UserInfo.vue";
import FormsPage from "../views/dashboard/FormsPage.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: IndexPage,
  },
  {
    path: "/form",
    name: "Form",
    component: FormPage,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },

  {
    path: "/dashboard/",
    name: "UserDashboard",
    component: UserDashboard,
    redirect: "/dashboard/main",
    children: [
      {
        path: "main",
        name: "MainPage",
        component: MainPage,
      },
      {
        path: "forms",
        name: "FormsPage",
        component: FormsPage,
      },
      {
        path: "info",
        name: "UserInfo",
        component: UserInfo,
      },
    ],
    //the protection layer for the rootes for unauthenticated
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
